.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box; 
}
main {
  display: flex;
  min-height: 85vh; 
  /* min-height: 100%; */
  flex: 1;
  flex-direction: row;
  margin: 0;
  align-items: stretch;
}
.col-1 {
  /* background: #D7E8D4; */
  background: rgb(213,225,227);
  display: flex;
  flex-direction: column;
  flex: 0;
  /* align-self: flex-start; */
  min-width: 300px;
  justify-content: stretch;
  /* space-between; */
  align-items: center;
}
.col-2 {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: row;
}
.content > article {
  flex: 1;
  flex-grow: 1;
  max-width: 60%;
}
.content > aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: beige; */
  background: rgb(217,217,217);
  max-width: 40%;
  flex: 1;
  align-self: stretch;
}
.upperMenu {
  min-width: 300px;
  justify-content: normal;
}
.settings {
  min-width: 300px;
  margin-top: auto;
}
.logo {
  display: flex;
  flex-direction: row;
  min-width: 300px;
  align-items: center;
  
}
header {
  height: 10vh;
  background: rgb(55,108,99);
  font-size: 25px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #FFFFFF;
  font-weight: 400;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: uppercase;
}
footer {
  height: 5vh;
  background: rgb(55,108,99);
  font-size: 10px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #FFFFFF;
  font-weight: 400;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: uppercase;
}
header, footer, article, nav, aside {
  padding: 1em;
}

.upload-asset {
  flex: 0;
}
.users-table {
  flex: 1;
}

.user-even-row {
  background: rgb(213,225,227);
}
.user-odd-row {
  background: rgb(217,217,217);
}

.main-button {
  background: rgb(55,108,99);
}

.invite-user-form {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}