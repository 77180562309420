@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype-variations');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Nunito Sans';
  /* src: url('./assets/fonts/NunitoSans.woff2') format('woff2'); */
  /* font-weight: 1 99; */
  src: url('./assets/fonts/NunitoSans-Light.ttf') format('truetype');
  font-weight: 400;
}

body {
  margin: 0;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #0058ff;
}

a:visited {
  color: #0058ff;
}

.MuiDataGrid-virtualScroller {
  height: 300px;
  width: 50%;
  overflow: auto;
  padding: 0;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 12px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: #0058ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiDrawer-paperAnchorDockedLeft::-webkit-scrollbar {
  width: 12px;
}

.MuiDrawer-paperAnchorDockedLeft::-webkit-scrollbar-track {
  background-color: #001e57;
  border: 1px solid #001e57;
}

.MuiDrawer-paperAnchorDockedLeft::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
}

@layer components {
  .text-splash-page-title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 700;

    @media (min-width: theme(screens.lg)) {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  .text-splash-page-subheader {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;

    @media (min-width: theme(screens.lg)) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .text-page-header {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;

    @media (min-width: theme(screens.lg)) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .text-section-header {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;

    @media (min-width: theme(screens.lg)) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .text-link {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 700;

    @media (min-width: theme(screens.lg)) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }

  .text-message {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
  }

  .text-table-header {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 700;
  }

  .text-table-data {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
  }
}
